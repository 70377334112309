<template>
  <div>
    <s-toolbar label="Control de Bus"></s-toolbar>
    <v-card elevation="5">
      <v-col>
        <s-select-definition
          @returnObject="returnObject($event)"
          radio
          :def="1116"
          :dgr="46"
          v-model="pvs"
        ></s-select-definition>
      </v-col>
      <v-col>
        <s-text label="Placa" v-model="placa"></s-text>
      </v-col>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="cancel()">Atras</v-btn>
        <v-btn color="primary" @click="start()">Iniciar</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>

import _sHmnBusTurnService from "@/services/HumanResource/HmnBusTurnService";

export default {
  name: "TimeRealReserve",
  components: {},
  props: {
    MltID: {
      type: Number,
      default: null,
    },
  },

  data: () => (
    { pvs: "1", dialog: true, item: null, placa: null }
  ),

  methods: {
    returnObject(item) {
      this.item = item;
    },

    start() {
      if (this.item != null) {
        if (this.placa != null) this.item.placa = this.placa;
        this.$emit("start", this.item);
        
      } else this.$fun.alert("Seleccione BUS", "warning");
      //  this.$router.push({ name: 'ACCESS_CONTROL_DETAIL', params: { pv: this.pvs } });
    },

    cancel() {
      this.$emit("cancel");
    },

    getRouteByDriver()
    {
      let obj = {
        BscDniDriver : this.$fun.getUserInfoLogin()
      }
      _smonitoringBusService
      .search(obj, this.$fun.getUserID())
      .then(resp => {
        if(resp.status == 200)
        {
          console.log('obtenemos todo cuando levanta el modal ', resp.data);
        }
      })
    }
  },
  mounted() {
    console.log(this.$route);
  },

  created () {
    this.getRouteByDriver();
  },
};
</script>

