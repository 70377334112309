import Service from "../Service";

const resource = "hmnbusturn/";

export default {
    save(obj, requestID) {
        return Service.post(resource + "save", obj, {
            params: { requestID: requestID },
        });
    },

    saveentrypersonbus(obj, requestID) {
        return Service.post(resource + "saveentrypersonbus", obj, {
            params: { requestID: requestID },
        });
    },

    busturnactive(obj, requestID) {
        return Service.post(resource + "busturnactive", obj, {
            params: { requestID: requestID },
        });
    },

    quantitypersonbyturnactive(obj, requestID) {
        return Service.post(resource + "quantitypersonbyturnactive", obj, {
            params: { requestID: requestID },
        });
    },


};